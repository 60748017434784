.column_selection {
    background-color: transparent;
    height: 0px !important;
  }
  
  .dhx_timeline_table_wrapper .dhx_marked_timespan {
    z-index: 9999;
    text-align: center;
  }
  
  .dhx_timeline_table_wrapper .dhx_marked_timespan .currTime  {
    width: max-content;
    position: relative;
    background-color: transparent;
    display: inline-flex;
    margin-top: 5px;
    font-size: 12px;
    z-index: 1;
    text-align: center;
  }
  
  .dhx_cal_light .dhx_cal_light_wide {
    z-index: 0 !important;
  }

  .dhx_matrix_line .dhx_cal_event_line  {
    border-radius: 5px;
    margin-top: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height:28% !important;
  }
  
  .event_text {
    color: white;
    /* line-height: 70px; */
  }
  
  /* .dhx_cal_prev_button,
  .dhx_cal_next_button {
    background: white;
    border: 1px solid skyblue;
    color: black;
    float: right;
    margin-left: 40px;
  } */
  
  /* .dhx_cal_prev_button:hover,
  .dhx_cal_next_button:hover {
    background-color: rgba(0, 0, 0, 0.1);
  } */
  
  .dhx_matrix_cell div, .dhx_matrix_scell .schedule-timeline-container {
    overflow: visible !important;
    text-align: center !important;
    height: 100% !important;
    width: 100%;
    display:flex;
    flex-direction: row;
    align-items: center;
  }
  
  .sub-container {
    display:flex;
    flex-direction: column;
  }
  
  .schedule-timeline-container .name {
    margin-top: 10px;
    text-transform: capitalize;
    align-items: center;
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 87px;
    min-width: 87px;
    text-align: left;
    padding-left: 5px;
  }
  
  .schedule-timeline-container .sub-name {
    text-transform: capitalize;
    align-items: center;
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  .schedule-timeline-container .active-name {
   text-transform: capitalize;
   align-items: center;
   flex: 1;
   display: flex;
   justify-content: center;
   padding-left: 5px;
  }
  
  .schedule-timeline-container .image {
    height: 50px;
    margin-bottom: -13px;
    flex:1;
    display: flex;
    margin-left: 5px;
    line-height: normal;
  }
  
  .button {
    height: 20px;
    width: 20px;
    position: relative;
  }
  
  .bell {
    height: 10px;
    width: 10px;
  }
  
  #bell_svg {
    height: 20px;
    width: 20px;
  }
  
  .btn {
    background-color: transparent;
    border: 1px solid #d9d9d9;
    color: black;
    padding: 3px 3px;
    font-size: 16px;
    cursor: pointer;
    outline: none;
  }

  .disable-modal {
    pointer-events: none !important;
  }
  
  /* Darker background on mouse-over */
  .btn:hover {
    border: 1px solid #40a9ff !important;
    color: #40a9ff !important;
    transition: 0.5s;
  }
  
  .btn:focus{
    outline:none !important;
    }
  
  .overrideAlert {
    padding-top: 5px;
    color: red;
    font-size: 12px;
    text-decoration: none;
  }
  
  .overrideAlert:hover {
    color: #40a9ff;
    transition: 0.5s;
  }
  
  .notification {
    color: white;
    text-decoration: none;
    /* position: absolute; */
    display: inline-block;
    border-radius: 2px;
  }
  
  .notification:hover {
    color: #40a9ff;
  }
  
  .notification .badge {
    position: relative;
    top: -35px;
    right: -12px;
    padding: 0px 5px;
    border-radius: 50%;
    background: red;
    color: white;
    height: 5px;
    font-size: 12px;
  }
  
  .notification .nobadge {
    position: relative;
    top: -78px;
    right: -12px;
    padding: 0px 5px;
    border-radius: 50%;
    color: white;
    height: 5px;
    font-size: 12px;
  }
  
  .svg-container {
    display: flex;
    margin-top: 5px;
  }
  
  div.dhx_timeline_label_row {
    line-height: 25px  !important;
  }


  .dhx_cal_data {
    overflow-y: auto;
  }

  /* ----------- iPhone 5, 5S, 5C and 5SE -----------*/
  @media only screen 
    and (min-device-width: 320px) 
    and (max-device-width: 568px)
    and (-webkit-min-device-pixel-ratio: 2)
    and (orientation: portrait) {
  
      .dhx_matrix_line .dhx_cal_event_line  {
        margin-top: 2px !important;
      }
  
      .notification .badge {
        top: -78px;
      }
  
      .twoweeks-mode {
        min-width:800px;
      }
  
      .month-mode {
        min-width: 850px;
      }
  
      .day-mode {
        min-width: 1200px;
      }
  
      .week-mode {
        min-width:750px;
      }
  
      .event_text {
        color: white;
        line-height: 35px;
      }
  
      .sub-container {
        height:50% !important;
      }
  
      .dhx_matrix_line .dhx_cal_event_line {
        height:25% !important;
      }
    }
